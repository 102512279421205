import actionTypes from '../constants/action-types';

const setUserData = (userData) => ({
  type: actionTypes.SET_USER_DATA,
  payload: {
    userData,
  },
});

export default setUserData;
