import sentryInstance from 'src/js/modules/analytics/sentry/instance';
import actionTypes from '../constants/action-types';

const errorHandler = () => (next) => (action) => {
  switch (action.type) {
    case actionTypes.SET_CRITICAL_ERROR: {
      const { error, errorInfo, logSentry } = action.payload;

      if (sentryInstance.isInit() && logSentry) {
        const instance = sentryInstance.getInstance();
        if (errorInfo) {
          instance.withScope((scope) => {
            scope.setExtras(errorInfo);
            instance.captureException(error);
          });
        } else {
          instance.captureException(error);
        }
      }

      next(action);
      break;
    }
    default:
      next(action);
  }
};

export default errorHandler;
