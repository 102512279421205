import React from 'react';

const WarningIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
    <path
      d="M64.41 63.0003H7.59004C7.05212 63.0156 6.52003 62.8855 6.04986 62.6237C5.57969 62.3619 5.18886 61.9781 4.91857 61.5128C4.64828 61.0474 4.50854 60.5178 4.51407 59.9797C4.51961 59.4416 4.67023 58.9149 4.95004 58.4553L33.402 9.15026C33.6654 8.69424 34.0441 8.31556 34.5001 8.05228C34.9561 7.789 35.4735 7.65039 36 7.65039C36.5266 7.65039 37.0439 7.789 37.5 8.05228C37.956 8.31556 38.3347 8.69424 38.598 9.15026L67.05 58.4553C67.3298 58.9149 67.4805 59.4416 67.486 59.9797C67.4915 60.5178 67.3518 61.0474 67.0815 61.5128C66.8112 61.9781 66.4204 62.3619 65.9502 62.6237C65.48 62.8855 64.948 63.0156 64.41 63.0003Z"
      fill="url(#paint0_linear_1372_76056)"
    />
    <g opacity="0.5">
      <path
        d="M64.41 63.0003H7.59004C7.05212 63.0156 6.52003 62.8855 6.04986 62.6237C5.57969 62.3619 5.18886 61.9781 4.91857 61.5128C4.64828 61.0474 4.50854 60.5178 4.51407 59.9797C4.51961 59.4416 4.67023 58.9149 4.95004 58.4553L33.402 9.15026C33.6654 8.69424 34.0441 8.31556 34.5001 8.05228C34.9561 7.789 35.4735 7.65039 36 7.65039C36.5266 7.65039 37.0439 7.789 37.5 8.05228C37.956 8.31556 38.3347 8.69424 38.598 9.15026L67.05 58.4553C67.3298 58.9149 67.4805 59.4416 67.486 59.9797C67.4915 60.5178 67.3518 61.0474 67.0815 61.5128C66.8112 61.9781 66.4204 62.3619 65.9502 62.6237C65.48 62.8855 64.948 63.0156 64.41 63.0003Z"
        fill="url(#paint1_linear_1372_76056)"
      />
      <path
        d="M64.41 63.0003H7.59004C7.05212 63.0156 6.52003 62.8855 6.04986 62.6237C5.57969 62.3619 5.18886 61.9781 4.91857 61.5128C4.64828 61.0474 4.50854 60.5178 4.51407 59.9797C4.51961 59.4416 4.67023 58.9149 4.95004 58.4553L33.402 9.15026C33.6654 8.69424 34.0441 8.31556 34.5001 8.05228C34.9561 7.789 35.4735 7.65039 36 7.65039C36.5266 7.65039 37.0439 7.789 37.5 8.05228C37.956 8.31556 38.3347 8.69424 38.598 9.15026L67.05 58.4553C67.3298 58.9149 67.4805 59.4416 67.486 59.9797C67.4915 60.5178 67.3518 61.0474 67.0815 61.5128C66.8112 61.9781 66.4204 62.3619 65.9502 62.6237C65.48 62.8855 64.948 63.0156 64.41 63.0003Z"
        fill="url(#paint2_linear_1372_76056)"
      />
      <path
        d="M64.41 63.0003H7.59004C7.05212 63.0156 6.52003 62.8855 6.04986 62.6237C5.57969 62.3619 5.18886 61.9781 4.91857 61.5128C4.64828 61.0474 4.50854 60.5178 4.51407 59.9797C4.51961 59.4416 4.67023 58.9149 4.95004 58.4553L33.402 9.15026C33.6654 8.69424 34.0441 8.31556 34.5001 8.05228C34.9561 7.789 35.4735 7.65039 36 7.65039C36.5266 7.65039 37.0439 7.789 37.5 8.05228C37.956 8.31556 38.3347 8.69424 38.598 9.15026L67.05 58.4553C67.3298 58.9149 67.4805 59.4416 67.486 59.9797C67.4915 60.5178 67.3518 61.0474 67.0815 61.5128C66.8112 61.9781 66.4204 62.3619 65.9502 62.6237C65.48 62.8855 64.948 63.0156 64.41 63.0003Z"
        fill="url(#paint3_linear_1372_76056)"
      />
    </g>
    <path
      d="M39 52.5C39 53.0933 38.8241 53.6734 38.4944 54.1667C38.1648 54.6601 37.6962 55.0446 37.1481 55.2716C36.5999 55.4987 35.9967 55.5581 35.4147 55.4424C34.8328 55.3266 34.2982 55.0409 33.8787 54.6213C33.4591 54.2018 33.1734 53.6672 33.0576 53.0853C32.9419 52.5033 33.0013 51.9001 33.2284 51.352C33.4554 50.8038 33.8399 50.3352 34.3333 50.0056C34.8266 49.6759 35.4067 49.5 36 49.5C36.7957 49.5 37.5587 49.8161 38.1213 50.3787C38.6839 50.9413 39 51.7044 39 52.5ZM37.389 25.5H34.611C34.4047 25.4995 34.2006 25.5415 34.0113 25.6235C33.822 25.7054 33.6516 25.8255 33.5108 25.9763C33.3701 26.1271 33.2619 26.3053 33.1931 26.4997C33.1243 26.6942 33.0964 26.9007 33.111 27.1065L34.3965 45.1065C34.4235 45.4856 34.5934 45.8403 34.8718 46.099C35.1502 46.3576 35.5165 46.501 35.8965 46.5H36.1035C36.4835 46.501 36.8498 46.3576 37.1282 46.099C37.4067 45.8403 37.5765 45.4856 37.6035 45.1065L38.889 27.1065C38.9036 26.9007 38.8757 26.6942 38.8069 26.4997C38.7381 26.3053 38.6299 26.1271 38.4892 25.9763C38.3484 25.8255 38.178 25.7054 37.9887 25.6235C37.7994 25.5415 37.5953 25.4995 37.389 25.5Z"
      fill="url(#paint4_linear_1372_76056)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1372_76056"
        x1="36"
        y1="7.65176"
        x2="36"
        y2="63.0003"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD782" />
        <stop offset="0.161" stopColor="#FED57C" />
        <stop offset="0.372" stopColor="#FCCE6C" />
        <stop offset="0.61" stopColor="#F9C351" />
        <stop offset="0.867" stopColor="#F4B42B" />
        <stop offset="1" stopColor="#F1AB15" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1372_76056"
        x1="36"
        y1="7.65176"
        x2="36"
        y2="63.0003"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD375" stopOpacity="0" />
        <stop offset="0.382" stopColor="#FFD374" stopOpacity="0.01" />
        <stop offset="0.52" stopColor="#FED171" stopOpacity="0.039" />
        <stop offset="0.619" stopColor="#FCCE6B" stopOpacity="0.092" />
        <stop offset="0.698" stopColor="#FACA63" stopOpacity="0.167" />
        <stop offset="0.766" stopColor="#F7C458" stopOpacity="0.265" />
        <stop offset="0.826" stopColor="#F3BD4A" stopOpacity="0.387" />
        <stop offset="0.881" stopColor="#EFB53B" stopOpacity="0.531" />
        <stop offset="0.931" stopColor="#E9AC28" stopOpacity="0.7" />
        <stop offset="0.975" stopColor="#E4A214" stopOpacity="0.884" />
        <stop offset="1" stopColor="#E09B07" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1372_76056"
        x1="5.37754"
        y1="62.2758"
        x2="53.2395"
        y2="33.5178"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD375" stopOpacity="0" />
        <stop offset="0.382" stopColor="#FFD374" stopOpacity="0.01" />
        <stop offset="0.52" stopColor="#FED171" stopOpacity="0.039" />
        <stop offset="0.619" stopColor="#FCCE6B" stopOpacity="0.092" />
        <stop offset="0.698" stopColor="#FACA63" stopOpacity="0.167" />
        <stop offset="0.766" stopColor="#F7C458" stopOpacity="0.265" />
        <stop offset="0.826" stopColor="#F3BD4A" stopOpacity="0.387" />
        <stop offset="0.881" stopColor="#EFB53B" stopOpacity="0.531" />
        <stop offset="0.931" stopColor="#E9AC28" stopOpacity="0.7" />
        <stop offset="0.975" stopColor="#E4A214" stopOpacity="0.884" />
        <stop offset="1" stopColor="#E09B07" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1372_76056"
        x1="65.6025"
        y1="63.7923"
        x2="17.9925"
        y2="32.8743"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD375" stopOpacity="0" />
        <stop offset="0.382" stopColor="#FFD374" stopOpacity="0.01" />
        <stop offset="0.52" stopColor="#FED171" stopOpacity="0.039" />
        <stop offset="0.619" stopColor="#FCCE6B" stopOpacity="0.092" />
        <stop offset="0.698" stopColor="#FACA63" stopOpacity="0.167" />
        <stop offset="0.766" stopColor="#F7C458" stopOpacity="0.265" />
        <stop offset="0.826" stopColor="#F3BD4A" stopOpacity="0.387" />
        <stop offset="0.881" stopColor="#EFB53B" stopOpacity="0.531" />
        <stop offset="0.931" stopColor="#E9AC28" stopOpacity="0.7" />
        <stop offset="0.975" stopColor="#E4A214" stopOpacity="0.884" />
        <stop offset="1" stopColor="#E09B07" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1372_76056"
        x1="36"
        y1="25.5"
        x2="36"
        y2="55.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4A4A4A" />
        <stop offset="1" stopColor="#212121" />
      </linearGradient>
    </defs>
  </svg>
);

export default WarningIcon;
