import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import actions from '../actions';
import dialogTypes from '../constants/dialog-types';
import redirectToUrl from '../../../utils/redirect-to-url';
import ActionDialog from '../components/action-dialog';

function ResetSiteUnpublishConfirmDialogContainer({ siteId, redirectUrl }) {
  const dispatch = useDispatch();
  const captions = {
    title: i18next.t('Unpublish site?'),
    description: i18next.t(
      'You are going to reset your site. Do you want to unpublish it as well, so your visitors would not be able to see the old version?'
    ),
    submit: i18next.t('Yes'),
    cancel: i18next.t('No'),
  };

  const handleSubmit = () =>
    dispatch(actions.show(dialogTypes.UNPUBLISH_SITE, { siteId, redirectUrl }));

  const handleCancel = () => {
    if (redirectUrl) {
      redirectToUrl(redirectUrl);
    }

    dispatch(actions.hide());
  };

  return <ActionDialog onCancel={handleCancel} onSubmit={handleSubmit} captions={captions} />;
}

ResetSiteUnpublishConfirmDialogContainer.propTypes = {
  siteId: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
};

export default ResetSiteUnpublishConfirmDialogContainer;
