import registerDialog from '../helpers/register-dialog';
import dialogs from '../helpers/registered-dialogs';

const register = (modalType, Component) => {
  if (dialogs.has(modalType)) {
    throw new Error(`Dialog with type "${modalType}" is already registered`);
  }

  registerDialog(modalType, Component);
};

export default register;
