import React from 'react';
import i18next from 'i18next';
import { designSystem } from '@yola/ws-ui';

const { Flag } = designSystem;

const getCountries = () => ({
  af: { name: i18next.t('Afghanistan'), iconComponent: <Flag code="af" /> },
  al: { name: i18next.t('Albania'), iconComponent: <Flag code="al" /> },
  dz: { name: i18next.t('Algeria'), iconComponent: <Flag code="dz" /> },
  ar: { name: i18next.t('Argentina'), iconComponent: <Flag code="ar" /> },
  am: { name: i18next.t('Armenia'), iconComponent: <Flag code="am" /> },
  au: { name: i18next.t('Australia'), iconComponent: <Flag code="au" /> },
  at: { name: i18next.t('Austria'), iconComponent: <Flag code="at" /> },
  az: { name: i18next.t('Azerbaijan'), iconComponent: <Flag code="az" /> },
  bs: { name: i18next.t('Bahamas'), iconComponent: <Flag code="bs" /> },
  bh: { name: i18next.t('Bahrain'), iconComponent: <Flag code="bh" /> },
  by: { name: i18next.t('Belarus'), iconComponent: <Flag code="by" /> },
  be: { name: i18next.t('Belgium'), iconComponent: <Flag code="be" /> },
  bz: { name: i18next.t('Belize'), iconComponent: <Flag code="bz" /> },
  bo: { name: i18next.t('Bolivia'), iconComponent: <Flag code="bo" /> },
  ba: { name: i18next.t('Bosnia and Herzegovina'), iconComponent: <Flag code="ba" /> },
  bw: { name: i18next.t('Botswana'), iconComponent: <Flag code="bw" /> },
  br: { name: i18next.t('Brazil'), iconComponent: <Flag code="br" /> },
  bg: { name: i18next.t('Bulgaria'), iconComponent: <Flag code="bg" /> },
  ca: { name: i18next.t('Canada'), iconComponent: <Flag code="ca" /> },
  cl: { name: i18next.t('Chile'), iconComponent: <Flag code="cl" /> },
  cn: { name: i18next.t('China'), iconComponent: <Flag code="cn" /> },
  co: { name: i18next.t('Colombia'), iconComponent: <Flag code="co" /> },
  ck: { name: i18next.t('Cook Islands'), iconComponent: <Flag code="ck" /> },
  cr: { name: i18next.t('Costa Rica'), iconComponent: <Flag code="cr" /> },
  hr: { name: i18next.t('Croatia'), iconComponent: <Flag code="hr" /> },
  cz: { name: i18next.t('Czech Republic'), iconComponent: <Flag code="cz" /> },
  dk: { name: i18next.t('Denmark'), iconComponent: <Flag code="dk" /> },
  do: { name: i18next.t('Dominican Republic'), iconComponent: <Flag code="do" /> },
  ec: { name: i18next.t('Ecuador'), iconComponent: <Flag code="ec" /> },
  eg: { name: i18next.t('Egypt'), iconComponent: <Flag code="eg" /> },
  ee: { name: i18next.t('Estonia'), iconComponent: <Flag code="ee" /> },
  fi: { name: i18next.t('Finland'), iconComponent: <Flag code="fi" /> },
  fr: { name: i18next.t('France'), iconComponent: <Flag code="fr" /> },
  ge: { name: i18next.t('Georgia'), iconComponent: <Flag code="ge" /> },
  de: { name: i18next.t('Germany'), iconComponent: <Flag code="de" /> },
  gr: { name: i18next.t('Greece'), iconComponent: <Flag code="gr" /> },
  gt: { name: i18next.t('Guatemala'), iconComponent: <Flag code="gt" /> },
  hn: { name: i18next.t('Honduras'), iconComponent: <Flag code="hn" /> },
  hk: { name: i18next.t('Hong Kong'), iconComponent: <Flag code="hk" /> },
  hu: { name: i18next.t('Hungary'), iconComponent: <Flag code="hu" /> },
  is: { name: i18next.t('Iceland'), iconComponent: <Flag code="is" /> },
  in: { name: i18next.t('India'), iconComponent: <Flag code="in" /> },
  id: { name: i18next.t('Indonesia'), iconComponent: <Flag code="id" /> },
  ir: { name: i18next.t('Iran'), iconComponent: <Flag code="ir" /> },
  iq: { name: i18next.t('Iraq'), iconComponent: <Flag code="iq" /> },
  ie: { name: i18next.t('Ireland'), iconComponent: <Flag code="ie" /> },
  il: { name: i18next.t('Israel'), iconComponent: <Flag code="il" /> },
  it: { name: i18next.t('Italy'), iconComponent: <Flag code="it" /> },
  jm: { name: i18next.t('Jamaica'), iconComponent: <Flag code="jm" /> },
  jp: { name: i18next.t('Japan'), iconComponent: <Flag code="jp" /> },
  jo: { name: i18next.t('Jordan'), iconComponent: <Flag code="jo" /> },
  kz: { name: i18next.t('Kazakhstan'), iconComponent: <Flag code="kz" /> },
  kr: { name: i18next.t('Korea'), iconComponent: <Flag code="kr" /> },
  kw: { name: i18next.t('Kuwait'), iconComponent: <Flag code="kw" /> },
  lv: { name: i18next.t('Latvia'), iconComponent: <Flag code="lv" /> },
  lb: { name: i18next.t('Lebanon'), iconComponent: <Flag code="lb" /> },
  ly: { name: i18next.t('Libyan Arab Jamahiriya'), iconComponent: <Flag code="ly" /> },
  li: { name: i18next.t('Liechtenstein'), iconComponent: <Flag code="li" /> },
  lt: { name: i18next.t('Lithuania'), iconComponent: <Flag code="lt" /> },
  lu: { name: i18next.t('Luxembourg'), iconComponent: <Flag code="lu" /> },
  mo: { name: i18next.t('Macau'), iconComponent: <Flag code="mo" /> },
  mk: { name: i18next.t('Macedonia'), iconComponent: <Flag code="mk" /> },
  my: { name: i18next.t('Malaysia'), iconComponent: <Flag code="my" /> },
  mt: { name: i18next.t('Malta'), iconComponent: <Flag code="mt" /> },
  mx: { name: i18next.t('Mexico'), iconComponent: <Flag code="mx" /> },
  md: { name: i18next.t('Moldova'), iconComponent: <Flag code="md" /> },
  ma: { name: i18next.t('Morocco'), iconComponent: <Flag code="ma" /> },
  nl: { name: i18next.t('Netherlands'), iconComponent: <Flag code="nl" /> },
  nz: { name: i18next.t('New Zealand'), iconComponent: <Flag code="nz" /> },
  ni: { name: i18next.t('Nicaragua'), iconComponent: <Flag code="ni" /> },
  no: { name: i18next.t('Norway'), iconComponent: <Flag code="no" /> },
  om: { name: i18next.t('Oman'), iconComponent: <Flag code="om" /> },
  pk: { name: i18next.t('Pakistan'), iconComponent: <Flag code="pk" /> },
  pa: { name: i18next.t('Panama'), iconComponent: <Flag code="pa" /> },
  py: { name: i18next.t('Paraguay'), iconComponent: <Flag code="py" /> },
  pe: { name: i18next.t('Peru'), iconComponent: <Flag code="pe" /> },
  pl: { name: i18next.t('Poland'), iconComponent: <Flag code="pl" /> },
  pt: { name: i18next.t('Portugal'), iconComponent: <Flag code="pt" /> },
  pr: { name: i18next.t('Puerto Rico'), iconComponent: <Flag code="pr" /> },
  qa: { name: i18next.t('Qatar'), iconComponent: <Flag code="qa" /> },
  ro: { name: i18next.t('Romania'), iconComponent: <Flag code="ro" /> },
  ru: { name: i18next.t('Russian Federation'), iconComponent: <Flag code="ru" /> },
  sa: { name: i18next.t('Saudi Arabia'), iconComponent: <Flag code="sa" /> },
  rs: { name: i18next.t('Serbia'), iconComponent: <Flag code="rs" /> },
  sc: { name: i18next.t('Seychelles'), iconComponent: <Flag code="sc" /> },
  sg: { name: i18next.t('Singapore'), iconComponent: <Flag code="sg" /> },
  sk: { name: i18next.t('Slovakia'), iconComponent: <Flag code="sk" /> },
  si: { name: i18next.t('Slovenia'), iconComponent: <Flag code="si" /> },
  za: { name: i18next.t('South Africa'), iconComponent: <Flag code="za" /> },
  es: { name: i18next.t('Spain'), iconComponent: <Flag code="es" /> },
  se: { name: i18next.t('Sweden'), iconComponent: <Flag code="se" /> },
  ch: { name: i18next.t('Switzerland'), iconComponent: <Flag code="ch" /> },
  sy: { name: i18next.t('Syrian Arab Republic'), iconComponent: <Flag code="sy" /> },
  tw: { name: i18next.t('Taiwan'), iconComponent: <Flag code="tw" /> },
  tj: { name: i18next.t('Tajikistan'), iconComponent: <Flag code="tj" /> },
  th: { name: i18next.t('Thailand'), iconComponent: <Flag code="th" /> },
  tt: { name: i18next.t('Trinidad and Tobago'), iconComponent: <Flag code="tt" /> },
  tn: { name: i18next.t('Tunisia'), iconComponent: <Flag code="tn" /> },
  tr: { name: i18next.t('Turkey'), iconComponent: <Flag code="tr" /> },
  tm: { name: i18next.t('Turkmenistan'), iconComponent: <Flag code="tm" /> },
  ua: { name: i18next.t('Ukraine'), iconComponent: <Flag code="ua" /> },
  ae: { name: i18next.t('United Arab Emirates'), iconComponent: <Flag code="ae" /> },
  gb: { name: i18next.t('United Kingdom'), iconComponent: <Flag code="gb" /> },
  us: { name: i18next.t('United States'), iconComponent: <Flag code="us" /> },
  uy: { name: i18next.t('Uruguay'), iconComponent: <Flag code="uy" /> },
  uz: { name: i18next.t('Uzbekistan'), iconComponent: <Flag code="uz" /> },
  ve: { name: i18next.t('Venezuela'), iconComponent: <Flag code="ve" /> },
  vn: { name: i18next.t('Viet Nam'), iconComponent: <Flag code="vn" /> },
  ye: { name: i18next.t('Yemen'), iconComponent: <Flag code="ye" /> },
  zw: { name: i18next.t('Zimbabwe'), iconComponent: <Flag code="zw" /> },
  sv: { name: i18next.t('El Salvador'), iconComponent: <Flag code="sv" /> },
});

export default getCountries;
