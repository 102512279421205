import actionTypes from '../constants/action-types';

const setUserPreferences = (userPreferences) => ({
  type: actionTypes.SET_USER_PREFERENCES,
  payload: {
    userPreferences,
  },
});

export default setUserPreferences;
