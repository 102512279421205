import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import classNames from 'classnames';
import { designSystem } from '@yola/ws-ui';
import user from 'src/js/modules/user';
import LegacyLabel from '../legacy-label';
import isLegacyTemplate from '../../verifiers/is-legacy-template';

const { platformComponents } = user.constants;
const { ActionButton, Heading, Paragraph, Popover, PopoverContent, PopoverTrigger, Link } =
  designSystem;

const POPOVER_WIDTH = 320;

function Site(props) {
  const {
    siteData,
    onDelete,
    onBuyDomain,
    publishSiteUrl,
    availablePlatformComponents,
    onPreviewClick,
    onDomainSettingsClick,
    eolLearnMoreUrl,
    onReset,
    onUnpublish,
    onExportSite,
    isLegacyLatitudeTemplatesEolEnabled,
  } = props;

  const { previewUrl, sitebuilderUrl, name, displayVhost, domainUpsell, templateBuildSlug } =
    siteData;
  const isSiteEditingRestricted =
    isLegacyTemplate(templateBuildSlug) && isLegacyLatitudeTemplatesEolEnabled;
  const sitePreviewClasses = classNames('ws-site__preview', {
    'ws-site__preview--restricted': isSiteEditingRestricted,
  });

  const stopPropagation = (event) => event.stopPropagation();
  const preventDefault = (event) => event.preventDefault();

  const handleDelete = (event) => {
    stopPropagation(event);
    onDelete();
  };

  return (
    <div className="ws-site">
      <div className="ws-site__container">
        <div
          className={sitePreviewClasses}
          {...(!isSiteEditingRestricted && { onClick: onPreviewClick })}
        >
          {previewUrl && <img src={previewUrl} alt="template-preview" />}
        </div>

        <div className="ws-site__preview-actions">
          {isSiteEditingRestricted ? (
            <Popover
              appearance="dark"
              placement="bottom-right"
              width={POPOVER_WIDTH}
              title={i18next.t('Editing Restricted')}
            >
              <PopoverTrigger>
                <ActionButton
                  size="small"
                  iconGlyph="lock"
                  disabled
                  label={i18next.t('Restricted')}
                />
              </PopoverTrigger>
              <PopoverContent>
                <span>
                  {i18next.t(
                    'Editing of legacy websites is deprecated. To enable editing again, please re-create your site.'
                  )}
                  &nbsp;
                  <Link href={eolLearnMoreUrl} appearance="white" isUnderlined>
                    {i18next.t('Learn more')}
                  </Link>
                </span>
                <span className="ws-site__popover-actions">
                  <ActionButton
                    size="small"
                    appearance="danger"
                    format="solid"
                    label={i18next.t('Reset site')}
                    onClick={onReset}
                  />
                  <Link
                    href="/"
                    target="_self"
                    appearance="white"
                    size="medium"
                    onClick={(event) => {
                      preventDefault(event);
                      onExportSite();
                    }}
                  >
                    {i18next.t('Download site .zip')}
                  </Link>
                </span>
              </PopoverContent>
            </Popover>
          ) : (
            <ActionButton
              size="small"
              target="_self"
              href={sitebuilderUrl}
              label={i18next.t('Edit site')}
              onClick={stopPropagation}
            />
          )}

          <ActionButton size="small" iconGlyph="trash" appearance="danger" onClick={handleDelete} />
        </div>
      </div>

      <div className="ws-site__details">
        <Heading type="heading-5" singleLine>
          {name}
          {isSiteEditingRestricted && (
            <>
              &nbsp;
              <LegacyLabel />
            </>
          )}
        </Heading>

        <div className="ws-site__details-domain">
          {displayVhost && (
            <React.Fragment>
              <Paragraph appearance="accent" isClipped>
                <a href={`https://${displayVhost}`} style={{ textDecoration: 'none' }}>
                  {displayVhost}
                </a>
              </Paragraph>

              {availablePlatformComponents.includes(platformComponents.DOMAINS) && (
                <React.Fragment>
                  <Paragraph appearance="medium-emphasis">-</Paragraph>

                  {isSiteEditingRestricted ? (
                    <ActionButton
                      size="small"
                      format="text"
                      onClick={onUnpublish}
                      label={i18next.t('Unpublish')}
                    />
                  ) : (
                    <ActionButton
                      size="small"
                      format="text"
                      onClick={() => onDomainSettingsClick(siteData)}
                      label={i18next.t('Settings')}
                    />
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}

          {!displayVhost && (
            <React.Fragment>
              <Paragraph appearance="medium-emphasis">{i18next.t('Not published')}</Paragraph>

              {publishSiteUrl && (
                <React.Fragment>
                  <Paragraph appearance="medium-emphasis">-</Paragraph>

                  <ActionButton
                    size="small"
                    format="text"
                    disabled={isSiteEditingRestricted}
                    href={publishSiteUrl}
                    label={i18next.t('Publish site')}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </div>

      {domainUpsell && availablePlatformComponents.includes(platformComponents.DOMAINS) && (
        <div className="ws-site__upsell">
          <div className="ws-site__upsell-details">
            <Heading type="heading-6" appearance="accent" singleLine>
              www.{domainUpsell}.com
            </Heading>
            <Paragraph>{i18next.t('might be available')}</Paragraph>
          </div>

          <ActionButton
            appearance="accent"
            format="solid"
            label={i18next.t('Buy now')}
            onClick={() => onBuyDomain(domainUpsell)}
          />
        </div>
      )}
    </div>
  );
}

Site.propTypes = {
  availablePlatformComponents: PropTypes.arrayOf(PropTypes.string),
  onPreviewClick: PropTypes.func,
  onDelete: PropTypes.func,
  onBuyDomain: PropTypes.func,
  siteData: PropTypes.shape({
    previewUrl: PropTypes.string,
    sitebuilderUrl: PropTypes.string,
    name: PropTypes.string,
    displayVhost: PropTypes.string,
    publishSiteUrl: PropTypes.string,
    domainUpsell: PropTypes.string,
    templateBuildSlug: PropTypes.string,
  }).isRequired,
  publishSiteUrl: PropTypes.string,
  onDomainSettingsClick: PropTypes.func.isRequired,
  eolLearnMoreUrl: PropTypes.string.isRequired,
  onReset: PropTypes.func,
  onUnpublish: PropTypes.func,
  onExportSite: PropTypes.func,
  isLegacyLatitudeTemplatesEolEnabled: PropTypes.bool,
};

Site.defaultProps = {
  availablePlatformComponents: [],
  onPreviewClick: Function.prototype,
  onDelete: Function.prototype,
  onBuyDomain: Function.prototype,
  publishSiteUrl: null,
  onReset: Function.prototype,
  onUnpublish: Function.prototype,
  onExportSite: Function.prototype,
  isLegacyLatitudeTemplatesEolEnabled: false,
};

export default Site;
