import templateSlugs from '../constants/template-slugs';

const { LATITUDE_TEMPLATE_SLUG, LEGACY_TEMPLATE_SLUG } = templateSlugs;

const isLegacyTemplate = (templateBuildSlug) => {
  if (templateBuildSlug) {
    return (
      templateBuildSlug.startsWith(LEGACY_TEMPLATE_SLUG) &&
      !templateBuildSlug.startsWith(LATITUDE_TEMPLATE_SLUG)
    );
  }

  return false;
};

export default isLegacyTemplate;
