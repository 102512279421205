const getCookie = (cname) => {
  const cookiesArray = document.cookie.split(';').map((cookie) => cookie.trim());

  const cookie = cookiesArray.find((cookieName) => cookieName.includes(cname));

  if (cookie) {
    return cookie.split('=')[1];
  }

  return false;
};

export default getCookie;
