import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import ActionDialog from '../components/action-dialog';

const LegacySiteErrorDialogContainer = ({ onDismiss }) => {
  const captions = {
    title: i18next.t('Something Went Wrong'),
    description: i18next.t('An issue occurred with your legacy website. Please try again soon.'),
    submit: i18next.t('Dismiss'),
  };

  return (
    <ActionDialog captions={captions} onSubmit={onDismiss} iconGlyph="attention" width={336} />
  );
};

LegacySiteErrorDialogContainer.propTypes = {
  onDismiss: PropTypes.func.isRequired,
};

export default LegacySiteErrorDialogContainer;
