import React from 'react';
import PropTypes from 'prop-types';
import { Modal, designSystem } from '@yola/ws-ui';

const {
  Box,
  Heading,
  Paragraph,
  DialogButtonGroup,
  DialogButtonGroupSeparator,
  DialogButton,
  Stack,
  HorizontalStack,
  Icon,
} = designSystem;

const DEFAULT_MODAL_WIDTH = 320;

const ActionDialog = (props) => {
  const { onCancel, onSubmit, captions, iconGlyph, width } = props;
  const { title, description, submit, cancel } = captions;

  return (
    <Modal
      isBodyScrollDisabled
      width={width}
      maxWidth={width}
      minWidth={width}
      height="auto"
      centered
      resizable={false}
      draggable={false}
      overlay="visible"
    >
      <div className="ws-action-dialog-content">
        <Box
          margintTop="spacing-xl"
          margintBottom="spacing-xl"
          margintLeft="spacing-xl"
          margintRight="spacing-xl"
        >
          <Stack gap="spacing-xs">
            {iconGlyph && (
              <HorizontalStack align="center">
                <Icon glyph={iconGlyph} size={32} type="error" />
              </HorizontalStack>
            )}
            <Stack gap={iconGlyph ? 'spacing-4-xs' : 'spacing-xs'}>
              <Heading type="heading-5" align="center">
                {title}
              </Heading>
              <Paragraph appearance="medium-emphasis" align="center">
                {description}
              </Paragraph>
            </Stack>
          </Stack>
        </Box>
        <DialogButtonGroup>
          <DialogButton onClick={onSubmit}>{submit}</DialogButton>
          {onCancel && (
            <>
              <DialogButtonGroupSeparator />
              <DialogButton onClick={onCancel}>{cancel}</DialogButton>
            </>
          )}
        </DialogButtonGroup>
      </div>
    </Modal>
  );
};

ActionDialog.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    submit: PropTypes.string.isRequired,
    cancel: PropTypes.string,
  }).isRequired,
  iconGlyph: PropTypes.string,
  width: PropTypes.number,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

ActionDialog.defaultProps = {
  iconGlyph: '',
  width: DEFAULT_MODAL_WIDTH,
  onCancel: null,
};

export default ActionDialog;
