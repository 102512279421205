import yowsServiceClient from '../../yows-service-client/service-client';
import statusNames from '../constants/status-names';
import status from '../../status';
import resolveServiceError from '../../../common/helpers/resolve-service-error';

const resetSite = (siteId) => async (dispatch) => {
  const yows = yowsServiceClient.get();

  dispatch(status.actions.setStatus(statusNames.SITE_RESET, status.constants.LOADING));

  try {
    const response = await yows.resetSite(siteId);

    dispatch(status.actions.setStatus(statusNames.SITE_RESET, status.constants.SUCCEEDED));

    return response;
  } catch (e) {
    const error = await resolveServiceError(e);

    console.error(error);
    dispatch(status.actions.setStatus(statusNames.SITE_RESET, status.constants.FAILED));

    throw error;
  }
};

export default resetSite;
