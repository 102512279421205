import actionTypes from '../constants/action-types';

const setCriticalError = ({ error, errorInfo, logSentry = true, ...rest }) => ({
  type: actionTypes.SET_CRITICAL_ERROR,
  payload: {
    error,
    errorInfo,
    logSentry,
    ...rest,
  },
});

export default setCriticalError;
