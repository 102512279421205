import client from '@yola/ws-service-client';
import yousers from '@yola/yousersjs';
import statusNames from '../constants/status-names';
import status from '../../status';
import config from '../../config';
import resolveServiceError from '../../../common/helpers/resolve-service-error';

const exportStatuses = {
  IN_PROGRESS: 'in_progress',
  COMPLETE: 'complete',
  FAILED: 'failed',
};

const exportSite =
  (siteId, options = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const wsApiUrl = config.selectors.getServiceAPIUrl(state);
    const { ws_auth_token: wsAuthToken } = await yousers.requests.generateAuthToken({
      site_id: siteId,
    });
    const apiClient = client.initWith(wsApiUrl, wsAuthToken);

    dispatch(status.actions.setStatus(statusNames.SITE_EXPORT, status.constants.LOADING));

    try {
      const response = await apiClient.exportSite(siteId, options);
      const {
        data: { id },
      } = response;

      // eslint-disable-next-line consistent-return
      const pollExportJob = async () => {
        const res = await apiClient.getAsyncJob(id);
        const {
          data: { status: exportStatus, results },
        } = res;

        switch (exportStatus) {
          case exportStatuses.IN_PROGRESS:
            return new Promise((resolve) => setTimeout(() => resolve(pollExportJob()), 1000));
          case exportStatuses.FAILED:
            dispatch(status.actions.setStatus(statusNames.SITE_EXPORT, status.constants.FAILED));
            throw res.data;
          case exportStatuses.COMPLETE:
            dispatch(status.actions.setStatus(statusNames.SITE_EXPORT, status.constants.SUCCEEDED));
            return results;
          default:
        }
      };

      return pollExportJob();
    } catch (e) {
      const error = await resolveServiceError(e);

      console.error(error);
      dispatch(status.actions.setStatus(statusNames.SITE_EXPORT, status.constants.FAILED));

      throw error;
    }
  };

export default exportSite;
