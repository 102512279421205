import deleteSite from './delete-site';
import loadSites from './load-sites';
import resetSite from './reset-site';
import unpublishSite from './unpublish-site';
import exportSite from './export-site';

export default {
  loadSites,
  deleteSite,
  resetSite,
  unpublishSite,
  exportSite,
};
