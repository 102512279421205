import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import sites from 'src/js/modules/sites';
import user from 'src/js/modules/user';
import dialogs from 'src/js/modules/dialogs';
import config from 'src/js/modules/config';
import actions from '../actions';
import redirectToUrl from '../../../utils/redirect-to-url';
import ConfirmActionDialog from '../components/confirm-action-dialog/index.js';

const CONFIRMATION_KEY_WORLD = 'RESET';
const YOLA_PARTNER_PUBLISHING_TYPE = 'YOLA';
const YOLA_PARTNER_ID = 'YOLA';
const SITE_ID_TO_RECREATE_KEY = 'site_id_to_recreate';
const TEMPLATE_BUILD_SLUG_KEY = 'template_build_slug';

const getCaptions = () => ({
  title: i18next.t('Reset website'),
  label: i18next.t('Type "{key}" to confirm', {
    key: CONFIRMATION_KEY_WORLD,
  }),
  description: i18next.t(
    'Reset your website if you want to start using another template. Please proceed with this step carefully. You cannot recover your site after the reset.'
  ),
  submit: i18next.t('Submit'),
  cancel: i18next.t('Cancel'),
});

const ResetSiteDialogContainer = ({ siteId, isPublished, templateBuildSlug }) => {
  const partnerData = useSelector(user.selectors.getPartnerData);
  const userData = useSelector(user.selectors.getUserData);
  const { publishingType } = userData;
  const dispatch = useDispatch();
  const galleryUrl = useSelector(config.selectors.getLatitudeTemplateGalleryUrl);
  const captions = getCaptions();
  const shouldUnpublishSite =
    isPublished &&
    (partnerData.id === YOLA_PARTNER_ID || publishingType === YOLA_PARTNER_PUBLISHING_TYPE);
  const redirectUrl = `${galleryUrl}?${SITE_ID_TO_RECREATE_KEY}=${siteId}&${TEMPLATE_BUILD_SLUG_KEY}=${templateBuildSlug}`;

  const closeDialog = useCallback(() => {
    dispatch(actions.hide());
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    dispatch(
      dialogs.actions.show(dialogs.dialogTypes.PENDING, {
        captions: {
          title: `${i18next.t('Reset website')}...`,
          description: i18next.t('Your website will be reset soon'),
        },
      })
    );

    dispatch(sites.thunks.resetSite(siteId))
      .then(() => {
        if (shouldUnpublishSite) {
          dispatch(
            dialogs.actions.show(dialogs.dialogTypes.RESET_SITE_UNPUBLISH_CONFIRM, {
              siteId,
              redirectUrl,
            })
          );

          return;
        }

        closeDialog();
        redirectToUrl(redirectUrl);
      })
      .catch(() => {
        dispatch(
          dialogs.actions.show(dialogs.dialogTypes.LEGACY_SITE_ERROR_DIALOG, {
            onDismiss: closeDialog,
          })
        );
      });
  }, [siteId, closeDialog, dispatch, shouldUnpublishSite, redirectUrl]);

  return (
    <ConfirmActionDialog
      onCancel={closeDialog}
      onSubmit={handleSubmit}
      captions={captions}
      confirmPhrase={CONFIRMATION_KEY_WORLD}
    />
  );
};

ResetSiteDialogContainer.propTypes = {
  siteId: PropTypes.string.isRequired,
  isPublished: PropTypes.bool.isRequired,
  templateBuildSlug: PropTypes.string.isRequired,
};

export default ResetSiteDialogContainer;
