import actionTypes from '../constants/action-types';

const updateUserData = (property, value) => ({
  type: actionTypes.UPDATE_USER_DATA,
  payload: {
    property,
    value,
  },
});

export default updateUserData;
