import actionTypes from '../constants/action-types';

const userData = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_DATA:
      return action.payload.userData;
    case actionTypes.UPDATE_USER_DATA:
      const { property, value } = action.payload;
      return {
        ...state,
        [property]: value,
      };
    default:
      return state;
  }
};

export default userData;
