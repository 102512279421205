import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import sites from 'src/js/modules/sites';
import dialogs from 'src/js/modules/dialogs';
import actions from '../actions';
import redirectToUrl from '../../../utils/redirect-to-url';
import ConfirmActionDialog from '../components/confirm-action-dialog/index.js';

const CONFIRMATION_KEY_WORLD = 'UNPUBLISH';

const getCaptions = () => ({
  title: i18next.t('Unpublish website'),
  label: i18next.t('Type "{confirmPhrase}" to confirm', {
    confirmPhrase: CONFIRMATION_KEY_WORLD,
  }),
  description: i18next.t(
    "Unpublish your site if you don't want it to remain online anymore. You will continue to own your domain and you will be able to publish another website using this domain name."
  ),
  submit: i18next.t('Submit'),
  cancel: i18next.t('Cancel'),
});

const UnpublishSiteDialogContainer = ({ siteId, redirectUrl }) => {
  const dispatch = useDispatch();
  const captions = getCaptions();

  const closeDialog = useCallback(() => {
    dispatch(actions.hide());
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    dispatch(
      dialogs.actions.show(dialogs.dialogTypes.PENDING, {
        captions: {
          title: i18next.t('Unpublishing website...'),
          description: i18next.t('Your website will be unpublished soon'),
        },
      })
    );

    dispatch(sites.thunks.unpublishSite(siteId))
      .then(() => {
        closeDialog();

        if (redirectUrl) {
          redirectToUrl(redirectUrl);
        }
      })
      .catch(() => {
        dispatch(
          dialogs.actions.show(dialogs.dialogTypes.LEGACY_SITE_ERROR_DIALOG, {
            onDismiss: closeDialog,
          })
        );
      });
  }, [siteId, closeDialog, dispatch, redirectUrl]);

  return (
    <ConfirmActionDialog
      onCancel={closeDialog}
      onSubmit={handleSubmit}
      captions={captions}
      confirmPhrase={CONFIRMATION_KEY_WORLD}
    />
  );
};

UnpublishSiteDialogContainer.propTypes = {
  siteId: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string,
};

UnpublishSiteDialogContainer.defaultProps = {
  redirectUrl: '',
};

export default UnpublishSiteDialogContainer;
