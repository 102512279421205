import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, designSystem } from '@yola/ws-ui';

const {
  Box,
  Heading,
  Paragraph,
  InputGroup,
  DialogButtonGroup,
  DialogButtonGroupSeparator,
  DialogButton,
  Stack,
} = designSystem;

const MODAL_WIDTH = 320;

const ConfirmActionDialog = (props) => {
  const { onCancel, onSubmit, captions, confirmPhrase } = props;
  const [inputValue, setInputValue] = useState('');
  const { title, label, description, submit, cancel } = captions;

  return (
    <Modal
      isBodyScrollDisabled
      width={MODAL_WIDTH}
      maxWidth={MODAL_WIDTH}
      minWidth={MODAL_WIDTH}
      height="auto"
      centered
      resizable={false}
      draggable={false}
      overlay="visible"
    >
      <div className="ws-confirm-action-dialog-content">
        <Box
          paddingTop="spacing-l"
          paddingBottom="spacing-l"
          paddingLeft="spacing-m"
          paddingRight="spacing-m"
        >
          <Stack gap="spacing-s">
            <Heading type="heading-6" appearance="danger" align="center">
              {title}
            </Heading>

            <Stack gap="spacing-3-xs">
              <Heading type="heading-6">{label}</Heading>
              <InputGroup placeholder={confirmPhrase} value={inputValue} onChange={setInputValue} />
              <Paragraph appearance="medium-emphasis" size="small">
                {description}
              </Paragraph>
            </Stack>
          </Stack>
        </Box>
        <DialogButtonGroup>
          <DialogButton disabled={inputValue.toUpperCase() !== confirmPhrase} onClick={onSubmit}>
            {submit}
          </DialogButton>
          <DialogButtonGroupSeparator />
          <DialogButton onClick={onCancel}>{cancel}</DialogButton>
        </DialogButtonGroup>
      </div>
    </Modal>
  );
};

ConfirmActionDialog.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    submit: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
  }).isRequired,
  confirmPhrase: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ConfirmActionDialog;
