import { useLayoutEffect, useState } from 'react';

const useMatchBreakpoint = (breakpoint) => {
  const [isMatches, setIsMatches] = useState(false);

  useLayoutEffect(() => {
    const mediaQuery = window.matchMedia(`(min-width: ${breakpoint})`);
    const handleChange = ({ matches }) => setIsMatches(matches);
    const shouldUseOldListeners = !mediaQuery.addEventListener;

    handleChange(mediaQuery);
    if (shouldUseOldListeners) {
      mediaQuery.addListener(handleChange);
    } else {
      mediaQuery.addEventListener('change', handleChange);
    }

    return () => {
      if (shouldUseOldListeners) {
        mediaQuery.removeListener(handleChange);
      } else {
        mediaQuery.removeEventListener('change', handleChange);
      }
    };
  }, [breakpoint]);

  return isMatches;
};

export default useMatchBreakpoint;
