import publishingServiceClient from '@yola/publishing-service-clientjs';
import config from 'src/js/modules/config';
import serviceClient from '../service-client';

const clientLoader = (store) => (next) => (action) => {
  switch (action.type) {
    case config.actionTypes.SET_SERVICE_CONFIG: {
      next(action);
      const state = store.getState();
      const apiUrl = config.selectors.getApiUrl(state);
      serviceClient.set(publishingServiceClient.initWith(apiUrl));
      break;
    }
    default: {
      next(action);
    }
  }
};

export default clientLoader;
